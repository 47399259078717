/* You can add global styles to this file, and also import other style files */

// @use "@angular/material/prebuilt-themes/indigo-pink.css";
@use "custom-theme";
@import "variables";
@import "@angular/cdk/overlay-prebuilt.css";

a {
  cursor: pointer;
}

html,
body {
  margin: 0;
}

html {
  height: 100%;
  font-size: 16px;
  @media screen and (max-width: "750px") {
    font-size: 14px;
  }
}

body {
  margin: 0;
  height: 100%;
  font-family: "Poppins", sans-serif;
}

.page-container {
  padding: 2rem;
  @media screen and (max-width: "599.98px") {
    padding: 1rem;
  }
}

input {
  font-size: 16px !important;
}

textarea {
  font-size: 16px !important;
}

// material overrides
.navbar.mat-toolbar-single-row {
  background-color: transparent;
  color: black;
  padding: 2rem 0 1rem 0;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  box-shadow: 0px 1px 5px lightgray;
}

.navbar-text-buttons {
  transition: color 0.2s ease;
  position: relative;
  &:hover {
    background-color: #fff;
    @media screen and (max-width: "750px") {
      background-color: transparent;
    }
    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $primary;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
    transform: scaleX(0);
    @media screen and (max-width: "750px") {
      background-color: #fff;
    }
  }
}

.active {
  color: $primary !important;
  &::after {
    transform: scaleX(1) !important;
    @media screen and (max-width: "750px") {
      background-color: #fff;
    }
  }
  @media screen and (max-width: "750px") {
    color: #fff !important;
  }
}

a,
a:visited {
  color: $primary !important;
  text-decoration: none;
}

.mdc-button.mat-primary {
  color: white !important;
}

.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0;
}

.mat-mdc-tab-body {
  padding: 1rem 0;
}

/* ------------------ Typography ------------------ */
// will fix all these to a proper theme file probably when i get time
// .page-title {
//   margin: 1rem 0 !important;
// }

/* -- Snack bar -- */
.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #ffffff;
  --mdc-snackbar-supporting-text-color: #ffffff;
  border-radius: 2px;
  &.notification-error {
    --mdc-snackbar-container-color: #{$warn};
  }

  &.notification-success {
    --mdc-snackbar-container-color: #{$primary};
  }
}

.search-dialog {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  &-container .mat-mdc-dialog-container .mat-mdc-dialog-surface {
    background: transparent;
    backdrop-filter: blur(10px);
  }
}

.mdc-dialog__actions {
  padding: 1rem !important;
}

.spacer {
  flex-grow: 1;
}

.action-footer {
  display: flex;
  justify-content: center;
  button:first-child {
    margin-right: 0.5rem;
  }
  @media screen and (max-width: 599.98px) {
    justify-content: flex-end;
  }
}

.notification-menu-panel {
  min-width: 300px !important;
  max-width: 350px !important;
}

.mat-mdc-table {
  .mdc-data-table__row {
    transition: ease 0.2s background-color;
    &:hover {
      background-color: #f7f7f7 !important;
      cursor: pointer;
    }
  }
}

/* ------------------ Cat tab ------------------ */
.cat-tab-group {
  .mat-mdc-tab-header {
    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        .mat-mdc-tab-labels {
          .mdc-tab {
            min-width: 45px;
            padding-inline: 1rem;
            &:hover {
              .mdc-tab__content {
                .mdc-tab__text-label {
                  color: black;
                }
              }
              .mdc-tab__ripple {
                &::before {
                  opacity: 0;
                }
              }
            }
            &:not(.mdc-tab--stacked) {
              height: 30px;
            }
            &.mdc-tab--active {
              .mdc-tab__content {
                .mdc-tab__text-label {
                  color: black;
                }
              }
            }
          }
        }
      }
    }
  }
  .mat-mdc-tab-body-wrapper {
    .mat-mdc-tab-body {
      .mat-mdc-tab-body-content {
        //overflow: hidden;
        padding-inline: 0.5rem;
      }
    }
  }
}

.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  max-width: 150px !important;
}

// Probably will get removed if we can make a message component with an icon and message
.icon-text-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  gap: 0.5rem;
  margin-bottom: 1.1rem;
  justify-content: left;
  align-items: center;
}

.mat-button-toggle-checked {
  background-color: $primary;
  &.mat-button-toggle-appearance-standard {
    color: white;
  }
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1002 !important;
}

p {
  line-height: 1.125rem;
}

.primary-color {
  color: $primary;
  font-weight: 600;
}

.value-field {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
    .mat-mdc-form-field-infix {
      min-height: 0px !important;
      padding-top: .5rem !important;
      padding-bottom: .5rem !important;
    }
  } 
}

.mat-mdc-dialog-container .mdc-dialog__content {
  line-height: 1.125rem !important;
}
